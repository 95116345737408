import { useUserStore } from '@/stores/user';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

let userStore = null;

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/LoginView.vue'),
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/RegisterView.vue'),
    },
    {
      path: '/reset',
      name: 'reset',
      component: () => import('@/views/ResetPasswordView.vue'),
    },
    {
      path: '/register/vendor',
      name: 'registerSeller',
      component: () => import('@/views/RegisterSellerView.vue'),
    },
    {
      path: '/accept/:id',
      name: 'acceptInvite',
      component: () => import('@/views/AcceptInviteView.vue'),
    },
    {
      path: '/',
      redirect: '/vendor/account',
      component: () => import('@/layouts/DefaultLayout.vue'),
      children: [
        {
          path: '/home',
          name: 'home',
          redirect: '/vendor/account',
        },
        {
          path: '/user',
          name: 'user',
          component: () => import('@/views/user/UserIndex.vue'),
        },
        {
          path: '/vendor/account',
          name: 'vendor', // 同时用于确定侧边栏的icon，必须与icon名称后缀保持一致
          component: () => import('@/views/company/MyCompanyView.vue'),
        },
        {
          path: '/vendor/users',
          name: 'vendor-users',
          component: () => import('@/views/company/CompanyUsersView.vue'),
        },
        {
          path: '/vendor/invites',
          name: 'vendor-invites',
          component: () => import('@/views/company/MyInvitesView.vue'),
        },
        {
          path: '/service/list',
          name: 'service',
          component: () => import('@/views/service/ServiceList.vue'),
        },
        {
          path: '/service/:id/assists',
          name: 'service-assists',
          component: () => import('@/views/service/ServiceAssistant.vue'),
        },
        {
          path: '/services/:id',
          name: 'edit-service',
          redirect: (to) => ({ name: 'service-info', params: to.params, query: to.query }),
          component: () => import('@/views/service/EditService.vue'),
          children: [
            {
              path: '/services/:id/info',
              name: 'service-info',
              component: () => import('@/views/service/BasicInfo.vue'),
            },
            {
              path: '/services/:id/more/',
              name: 'service-more',
              component: () => import('@/views/service/MoreInfo.vue'),
            },
            {
              path: '/services/:id/resource',
              name: 'service-resource',
              component: () => import('@/views/service/ServiceResource.vue'),
            },
            {
              path: '/services/:id/price',
              name: 'service-price',
              component: () => import('@/views/service/ServicePrice.vue'),
            },
            {
              path: '/services/:id/added',
              name: 'service-added',
              component: () => import('@/views/service/AddedService.vue'),
            },
            {
              path: '/service/:id/node',
              name: 'service-node',
              component: () => import('@/views/service/ServiceNode.vue'),
            },
            {
              path: '/service/recommend/:id',
              name: 'service-recommend',
              component: () => import('@/views/service/ServiceRecommend.vue'),
            },
          ],
        },
        {
          path: '/wallet/balance',
          name: 'wallet-balance',
          component: () => import('@/views/wallet/BalanceView.vue'),
        },
        {
          path: '/wallet/record',
          name: 'wallet-record',
          component: () => import('@/views/wallet/TradeRecord.vue'),
        },
        {
          path: '/wallet/allocate',
          name: 'wallet-allocate',
          component: () => import('@/views/wallet/AllocateMoneyView.vue'),
        },
        {
          path: '/wallet/withdraw',
          name: 'wallet-withdraw',
          component: () => import('@/views/wallet/WithdrawList.vue'),
        },
        {
          path: '/orders',
          name: 'order',
          component: () => import('@/views/order/OrderList.vue'),
        },
        {
          path: '/order/services/:id',
          name: 'order-service',
          component: () => import('@/views/order/ServiceDetail.vue'),
        },
        {
          path: '/order/services/:id/nodes',
          name: 'order-nodes',
          component: () => import('@/views/order/ServiceProgress.vue'),
        },
        {
          path: '/notices',
          name: 'notice',
          component: () => import('@/views/notice/NoticeList.vue'),
        },
        {
          path: '/notices/:id',
          name: 'notice-detail',
          component: () => import('@/views/notice/NoticeDetail.vue'),
        },
        {
          path: '/coupon/batches',
          name: 'coupon-batch',
          component: () => import('@/views/coupon/CouponBatch.vue'),
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (!userStore) {
    userStore = useUserStore();
    await userStore.initUser();
  }
  next();
});

export default router;
