import axios from 'axios';
import { AXIOS_BASE_URL, ResponseCode, SPACE_HEADER_KEY } from './consts';
import { goLogin } from '@/common/utils';
import { useUserStore } from '@/stores/user';

let userStore = null;

const service = axios.create({
  baseURL: AXIOS_BASE_URL,
  timeout: 60000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    // json
    config.headers['Content-Type'] = 'application/json';
    config.headers['Accept-Language'] = 'en';
    // token
    if (!userStore) {
      userStore = useUserStore();
    }
    if (userStore.token) {
      config.headers['Authorization'] = userStore.getToken();
    }
    // 加空间标识
    if (userStore.shop) {
      config.headers[SPACE_HEADER_KEY] = userStore.getShopId();
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    const { code, msg, data } = response.data;

    // 401
    if (code === ResponseCode.UNAUTHORIZED) {
      if (!userStore) {
        userStore = useUserStore();
      }
      // 跳转到登录页前清除token，防止登录页携带该无效token请求报401，重定向参数改为了登录页
      userStore.clearLocalUser();
      goLogin();
      return { code, msg, data };
    }
    return { code, msg, data };
  },
  function (error) {
    return { code: 500, msg: error.message, data: null };
  },
);

/*
 * 字符串参数拼接
 * @encodeParams {a: 1, b: 222, c: 3}
 * @return "a=1&b=222&c=3"
 */
export function encodeParams(data) {
  let url = '';
  for (var k in data) {
    const value = data[k] !== undefined ? data[k] : '';
    url += '&' + k + '=' + encodeURIComponent(value);
  }
  return url ? url.substring(1) : ''; // 删掉第一个字母&
}

export default {
  // get请求
  get(url, params, config = {}) {
    return new Promise((resolve, reject) => {
      url += (url.indexOf('?') < 0 ? '?' : '&') + encodeParams(params);
      service
        .get(url, {
          ...config,
          headers: { ...config?.headers },
        })
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          },
        )
        .catch((error) => {
          reject(error);
        });
    });
  },
  post(url, params, config) {
    return new Promise((resolve, reject) => {
      service
        .post(url, params, {
          ...config,
          headers: { ...config?.headers },
        })
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          },
        )
        .catch((error) => {
          reject(error);
        });
    });
  },
  put(url, params, config) {
    return new Promise((resolve, reject) => {
      service
        .put(url, params, {
          ...config,
          headers: { ...config?.headers },
        })
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          },
        )
        .catch((error) => {
          reject(error);
        });
    });
  },
  patch(url, params, config) {
    return new Promise((resolve, reject) => {
      service
        .patch(url, params, {
          ...config,
          headers: { ...config?.headers },
        })
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          },
        )
        .catch((error) => {
          reject(error);
        });
    });
  },
  delete(url, params) {
    url += (url.indexOf('?') < 0 ? '?' : '&') + encodeParams(params);
    return new Promise((resolve, reject) => {
      service
        .delete(url)
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          },
        )
        .catch((error) => {
          reject(error);
        });
    });
  },
};
