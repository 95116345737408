import Vue from 'vue';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';

import App from './App.vue';
import pinia from './common/pinia';
import router from './router';
import axios from './common/axios';

import '@/assets/css/element.scss';
import '@/assets/css/public.scss';
import '@/assets/css/font.scss';

// size: 组件的默认尺寸 zIndex 弹框的初始2000
Vue.use(ElementUI, { size: 'medium', zIndex: '3000', locale });

// axios
Vue.use(VueAxios, { $axios: axios });

// cookies
// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies);

new Vue({
  router,
  render: (h) => h(App),
  pinia,
}).$mount('#app');
