import axios from 'axios';
import router from '@/router';
import moment from 'moment';
import { v1 as uuidv1 } from 'uuid';

import { preupload } from '@/api/tool';
import { Currency, ResponseCode } from './consts';

/**
 * route转成绝对路径
 * @param {*} routeParam
 * @returns
 */
export const getAbsoluteUrl = (routeParam) => {
  const route = router.resolve(routeParam);
  return new URL(route.href, window.location.origin).href;
};

/**
 * 跳转到登录页
 */
export const goLogin = (redirect = true) => {
  let loginUrl = null;
  if (redirect && router.currentRoute.name !== 'login') {
    // 生成新的路由，加上space查询参数
    const redirectUrl = getAbsoluteUrl({ path: router.currentRoute.fullPath });
    loginUrl = getAbsoluteUrl({ name: 'login', query: { redirect: redirectUrl } });
  } else {
    loginUrl = getAbsoluteUrl({ name: 'login' });
  }
  window.open(loginUrl, '_self');
};

/**
 * json转 fromdata
 */
export function json2FormData(params) {
  const formData = new FormData();
  Object.keys(params).forEach((item) => {
    if (params[item] !== undefined && params[item] !== null) {
      formData.append(item, params[item]);
    }
  });
  return formData;
}

/**
 * 文件上传到OSS，先调用预上传接口获取参数，再请求OSS服务器
 */
export const uploadOss = async ({ file, use }) => {
  const preRes = await preupload({
    use: use,
    filename: file.name,
    size: file.size,
    type: file.type,
  });
  if (preRes.code === ResponseCode.OK) {
    const { accessId, policy, signature, callback, key, host, fileId } = preRes.data;
    const extraData = {
      OSSAccessKeyId: accessId,
      policy,
      signature,
      callback,
      key,
      ['x:id']: fileId,
    };
    extraData['success_action_status'] = '200'; // 单独赋值，规避变量命名驼峰规则检查
    const formData = json2FormData(extraData);
    formData.append('file', file, file.name);
    const { data } = await axios.post(host, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } else {
    return preRes;
  }
};

/**
 * 生成新的序号
 * @param {*} orderedList 已按序号升序排列过的数组
 * @returns
 */
export const newSerialNo = (orderedList) => {
  if (orderedList.length) {
    return orderedList[orderedList.length - 1].serialNo + 1;
  } else {
    return 1;
  }
};

export const formatNumber = (number) => {
  if (number !== 0 && !number) {
    // null / undefined 直接返回空字符串
    return '';
  }
  // 拆成小数部分和整数部分
  const splits = number.toString().split('.');
  let integerPart = '';
  let decimalPart = '';
  if (splits.length === 2) {
    [integerPart, decimalPart] = splits;
  } else {
    integerPart = splits[0];
  }
  let newInteger = '';
  // 整数部分从右到左每三位加逗号
  // 获取要插入逗号的位置
  const first = integerPart.length % 3;
  const leftCount = parseInt(integerPart.length / 3);
  const positions = []; // 记录所有要插入逗号的位置
  if (first > 0 && leftCount > 0) {
    positions.push(first);
  }
  for (let i = 1; i < leftCount; i++) {
    positions.push(first + i * 3);
  }
  if (positions.length) {
    // 按插入逗号的位置，一段一段截取数字拼接逗号
    positions.forEach((pos, idx) => {
      // 确定截取的起点和终点（前闭后开的区间），终点是插入位置，起点：第一段从0开始，往后的每一段的起点都是上一段的终点
      let start = null;
      if (idx === 0) {
        start = 0;
      } else {
        start = positions[idx - 1];
      }
      newInteger += integerPart.slice(start, pos) + ',';
    });
    newInteger += integerPart.slice(positions[positions.length - 1], integerPart.length); // 最后部分
  } else {
    newInteger = integerPart;
  }
  let newNumber = '';
  if (decimalPart.length) {
    newNumber = `${newInteger}.${decimalPart}`;
  } else {
    newNumber = newInteger;
  }
  return newNumber;
};

/**
 * 格式化价格，带上货币符号
 * @param {*} price 价格，以分/美分为单位
 * @param {*} currency 币种, Curreny.CNY/Currency.USD
 * @returns
 */
export const formatPrice = (price, currency = Currency.CNY) => {
  const newNumber = formatNumber(price / 100);
  if (currency === Currency.CNY) {
    return `¥${newNumber}`;
  } else {
    return `$${newNumber}`;
  }
};

export const formatTime = (dt) => {
  return moment(dt).local().format('YYYY-MM-DD HH:mm:ss');
};

export const getUuid = () => {
  const v1options = {
    node: [0x01, 0x23, 0x45, 0x67, 0x89, 0xab],
    clockseq: 0x1234,
    msecs: +new Date(),
    nsecs: 5678,
  };
  return uuidv1(v1options);
};
