import axios from '@/common/axios';

/**
 * 发送短信验证码
 * @param {*} body
 * @returns
 */
export const sendSmsCode = async (body) => {
  return await axios.post('/tool/code/sms', body);
};

/**
 * 发送邮箱验证码
 * @param {*} body
 * @returns
 */
export const sendEmailCode = async (body) => {
  return await axios.post('/tool/code/email', body);
};

/**
 * 预上传，获取上传到文件服务器的参数
 * @param {*} body
 * @returns
 */
export const preupload = async (body) => {
  return await axios.post('/tool/oss/preupload', body);
};
