import Vue from 'vue';
import { defineStore } from 'pinia';
import { getUserInfo } from '@/api/user';
import { COOKIE_DOMAIN, ResponseCode } from '@/common/consts';

export const useUserStore = defineStore('user', {
  state: () => ({
    token: null,
    user: null,
    shop: null, // 当前服务商
    menuList: [],

    shopList: [],
    adminMenuList: [],
    staffMenuList: [],
  }),
  actions: {
    // 获取存储的token
    getStorageToken() {
      return Vue.$cookies.get('token');
    },
    // 删除存储的token
    deleteStorageToken() {
      Vue.$cookies.remove('token', '/', COOKIE_DOMAIN);
    },
    // 获取 token，store没有时从cookie取
    getToken() {
      if (this.token) {
        return this.token;
      }
      const oldToken = this.getStorageToken();
      if (oldToken) {
        // oldToken可能过期
        this.token = oldToken;
        return oldToken;
      }
      return null;
    },
    // 设置token
    setToken(token, persistent, remembered) {
      if (persistent) {
        let expires = '0';
        if (remembered) {
          expires = '7d';
        }
        Vue.$cookies.set('token', token, expires, '/', COOKIE_DOMAIN);
      }
      this.token = token;
    },
    // 从存储中载入token
    setTokenFromStorage() {
      const token = this.getStorageToken();
      this.setToken(token);
    },
    setUserData(data) {
      // 重置用户数据
      this.user = data.user;
      this.shopList = data.shop.list;
      this.staffMenuList = data.shop.staffMenuList;
      this.adminMenuList = data.shop.adminMenuList;
    },
    updateUser(data) {
      // 部分更新用户信息
      if (this.user) {
        Object.entries(data).forEach(([key, value]) => {
          if (key in this.user) {
            this.user[key] = value;
          }
        });
      }
    },
    async setRemoteUserData() {
      // 设置user,menu数据
      const { code, data } = await getUserInfo();
      if (code === ResponseCode.OK) {
        this.setUserData(data);
      }
    },
    setShop(shopId) {
      const intShopId = parseInt(shopId);
      // 不正常的服务商也允许设置
      const i = this.shopList.findIndex((item) => item.id === intShopId);
      if (i === -1) {
        return;
      }
      const shop = this.shopList[i];
      // 设置366天有效期，避免需要重复选择企业
      Vue.$cookies.set('shopId', intShopId, { expires: '365d' });
      this.shop = shop;
      // 设置管理后台侧边栏菜单
      if (this.shop.isAdmin) {
        this.menuList = this.adminMenuList;
      } else {
        this.menuList = this.staffMenuList;
      }
    },
    setShopFromStorage() {
      const rawShopId = Vue.$cookies.get('shopId');
      if (rawShopId) {
        this.setShop(rawShopId);
        return;
      }
      // 如果未设置过，且只有一个服务商时，直接设置该服务商
      if (this.shopList.length === 1) {
        this.setShop(this.shopList[0].id);
      }
    },
    deleteStorageShop() {
      Vue.$cookies.remove('shopId');
    },
    getShopId() {
      return Vue.$cookies.get('shopId');
    },
    // 清空本地用户数据，包括内存和存储的
    clearLocalUser() {
      this.deleteStorageToken();
      this.deleteStorageShop();
      this.$reset();
    },
    // 初始化用户
    async initUser() {
      this.setTokenFromStorage();
      if (this.token) {
        await this.setRemoteUserData();
        this.setShopFromStorage();
      }
    },
  },
});
