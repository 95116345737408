export const AXIOS_BASE_URL = process.env.VUE_APP_AXIOS_BASE_URL;
export const CDN_BASE_URL = process.env.VUE_APP_CDN_BASE_URL;
export const MALL_ORIGIN = process.env.VUE_APP_MALL_ORIGIN;
export const SELLER_ORIGIN = process.env.VUE_APP_SELLER_ORIGIN;
export const COOKIE_DOMAIN = process.env.VUE_APP_COOKIE_DOMAIN;
export const WS_BASE_URL = process.env.VUE_APP_WS_BASE_URL;

// 空间类型
export const Space = {
  PERSONAL: 1,
  MEMBER: 2,
  SELLER: 3,
};

export const SpaceType = {
  COMPANY: 1,
  SHOP: 2,
};

// 空间状态
export const SpaceStatus = {
  ACTIVE: 1, // 正常
  FROZEN: 2, // 冻结
  CLOSED: 3, // 注销
  INACTIVE: 4, // 未激活
};

// 用户状态
export const UserStatus = {
  ACTIVE: 1, // 正常
  FROZEN: 2, // 冻结
  CLOSED: 3, // 注销
};

// 服务状态
export const ServiceStatus = {
  EDITING: 1,
  UNLISTED: 2,
  AUDITING: 3,
  DENIED: 4, // 审核不通过
  LISTED: 5,
};

// 币种
export const Currency = {
  USD: 'USD',
  CNY: 'CNY',
};

// 问题类型
export const QuestionType = {
  SINGLE_CHOICE: 1,
  MULTIPLE_CHOICE: 2,
  SHORT_ANSWER: 3,
};

// 订单状态
export const OrderStatus = {
  UNPAID: 1, // 未支付
  IN_DELIVERY: 2, // 交付中
  CLOSED: 3, // 已关闭
  COMPLETED: 4, // 已完成
  CANCELED: 5, // 已取消
  REFUNDING: 6, // 退款中
};

// 订单状态描述
export const OrderStatusMap = new Map([
  [OrderStatus.UNPAID, { name: 'Unpaid', showed: true }],
  [OrderStatus.IN_DELIVERY, { name: 'In Delivery', showed: true }],
  [OrderStatus.COMPLETED, { name: 'Completed', showed: true }],
  [OrderStatus.CLOSED, { name: 'Closed', showed: true }],
  [OrderStatus.CANCELED, { name: 'Canceled', showed: false }],
  [OrderStatus.REFUNDING, { name: 'Refunding', showed: false }],
]);

// 交易类型
export const TradeType = {
  RECHARGE: 1, // 充值
  PAY: 2, // 付款
  WITHDRAW: 3, // 提现
  REFUND: 4, // 退款
  TRANSFER: 5, // 转账
};

// 交易类型描述
export const TradeTypeMap = new Map([
  [TradeType.RECHARGE, 'Recharge'],
  [TradeType.PAY, 'Pay'],
  [TradeType.WITHDRAW, 'Withdraw'],
  [TradeType.REFUND, 'Refund'],
  [TradeType.TRANSFER, 'Transfer'],
]);

// 交易方向
export const TradeDirection = {
  EXPENDITURE: 0,
  INCOME: 1,
};

// 提现状态
export const WithdrawStatus = {
  AUDITING: 1,
  REJECTED: 2,
  DEDUCT_FAILED: 3,
  TRANSFERRING: 4,
  COMPLETED: 5,
};

// 提现状态描述
export const WithdrawStatusMap = new Map([
  [WithdrawStatus.AUDITING, 'Auditing'],
  [WithdrawStatus.REJECTED, 'Rejected'],
  [WithdrawStatus.DEDUCT_FAILED, 'Deduct Failed'],
  [WithdrawStatus.TRANSFERRING, 'Transferring'],
  [WithdrawStatus.COMPLETED, 'Completed'],
]);

// 里程碑状态
export const NodeStatus = {
  INACTIVE: 1, // 未激活，完成支付后激活
  NOT_STARTED: 2, // 未开始
  ONGOING: 3, // 进行中
  COMPLETED: 4, // 交付完成
  OVERDUE: 5, // 逾期结束
  CLOSED: 6, // 服务关闭结束
};

// 里程碑状态描述
export const NodeStatusMap = new Map([
  [NodeStatus.INACTIVE, 'Inactive'],
  [NodeStatus.NOT_STARTED, 'Not Started'],
  [NodeStatus.ONGOING, 'Ongoing'],
  [NodeStatus.COMPLETED, 'Completed'],
  [NodeStatus.OVERDUE, 'Overdue'],
  [NodeStatus.CLOSED, 'Closed'],
]);

// 聊天类型
export const ChatType = {
  ONLINE_CHAT: 'ONLINE_CHAT',
  OPEN_ROOM: 'OPEN_ROOM',
};

// 聊天记录类型
export const ChatRecordType = {
  CHAR: 1,
  FILE: 2,
};

// 地域类型
export const AreaType = {
  REGION: 1,
  COUNTRY: 2,
  CITY: 3,
};

// 分类代码
export const CategoryCode = {
  COMMON: 'COMMON',
  PRESS_RELEASE: 'PRESS_RELEASE',
  OUTDOOR_SCREEN: 'OUTDOOR_SCREEN', // 大屏
  MEDIA_RELEASE: 'MEDIA_RELEASE', // 定投
  NEWSPAPER: 'NEWSPAPER', // 纸媒
  TV: 'TV', // 电视广告
  KOL: 'KOL', // 红人
  AWARD: 'AWARD', // 奖项
};

// 语言
export const Language = {
  ZH_HANS: 'zh-hans', // 简体中文
  EN: 'en', // 英语
};

export const SPACE_HEADER_KEY = 'x-xb-space';

export const SystemServiceNo = {
  CUSTOM: '100001',
};

export const ResponseCode = {
  OK: 20000,
  CREATED: 20100,
  NO_CONTENT: 20400,
  BAD_REQUEST: 40000,
  UNAUTHORIZED: 40100,
  USER_FROZEN: 40101, // 用户被冻结
  SPACE_FROZEN: 40102, // 所在企业或服务商被冻结
  FORBIDDEN: 40300, // 无权限（通用）
  SPACE_REQUIRED: 40301, // 需要加入企业或服务商
  VIP_REQUIRED: 40302, // 需要成为会员
  VIP_EXPIRED: 40303, // 会员已过期
  NOT_FOUND: 40400,
  METHOD_NOT_ALLOWED: 40500,
  TOO_MANY_REQUESTS: 42900, // 请求过于频繁
  INTERNAL_SERVER_ERROR: 50000, // 服务器内部错误
};
