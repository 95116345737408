import axios from '@/common/axios';

/**
 * 密码登录
 * @param {*} body
 * @returns
 */
export const passwordLogin = async (body) => {
  return await axios.post('/user/login/pwd', body);
};

/**
 * 短信验证码登录
 * @param {*} body
 * @returns
 */
export const smsLogin = async (body) => {
  return await axios.post('/user/login/sms', body);
};

/**
 * 登出
 * @returns
 */
export const logout = async () => {
  return await axios.post('/user/logout');
};

/**
 * 查询用户信息
 * @returns
 */
export const getUserInfo = async () => {
  return await axios.get('/user/info');
};

/**
 * 修改用户名
 * @param {*} body
 * @returns
 */
export const changeUsername = async (body) => {
  return await axios.post('/user/username/change', body);
};

/**
 * 修改昵称
 * @param {*} body
 * @returns
 */
export const changeNickname = async (body) => {
  return await axios.post('/user/nickname/change', body);
};

/**
 * 更改邮箱
 * @param {*} body
 * @returns
 */
export const changeEmail = async (body) => {
  return await axios.post('/user/email/change', body);
};

/**
 * 更改手机号码
 * @param {*} body
 * @returns
 */
export const changeMobile = async (body) => {
  return await axios.post('/user/mobile/change', body);
};

/**
 * 修改密码
 * @param {*} body
 * @returns
 */
export const changePassword = async (body) => {
  return await axios.post('/user/pwd/change', body);
};

/**
 * 修改用户头像
 * @param {*} body
 * @returns
 */
export const changePhoto = async (body) => {
  return await axios.post('/user/photo/change', body);
};

export const getWsToken = async () => {
  return await axios.post('/user/ws/token');
};

export const changeHasWhatsapp = async (body) => {
  return await axios.post('/user/whatsapp/change', body);
};

export const changePayPassword = async (body) => {
  return await axios.post('/user/paypwd/change', body);
};
